import { combineReducers } from "redux"

function factoryReducer(initialState) {
  return filterType => (state = initialState, action) => {
    switch (action.type) {
      case `UPDATE_FILTER_${filterType}`:
        return action.payload
      case `CLEAR_FILTER_${filterType}`:
      case "CLEAR_ALL_SEARCH_FILTERS":
        return initialState
      default:
        return state
    }
  }
}

const listReducer = factoryReducer([])
const objectReducer = factoryReducer(null)

export default combineReducers({
  // primary filters
  nodeType: listReducer("node-type"),
  nodeStatus: objectReducer("node-status"),
  customer: listReducer("customer"),
  location: listReducer("location"),
  nodeRole: listReducer("node-role"),

  // additional filters
  osFullName: listReducer("os-full-name"),
  os: listReducer("os"),
  processorType: listReducer("processor-type"),
  containsText: objectReducer("contains-text"),
  deviceName: objectReducer("device-name"),
  deviceMake: objectReducer("device-make"),
  nodeTeamviewerId: objectReducer("node-teamviewer-id"),
  nodePolicies: listReducer("node-policies"),
  memoryCapacity: objectReducer("memory-capacity"),
  driveTotalSpace: objectReducer("drive-total-space"),
  volumeFreeSpace: objectReducer("volume-free-space"),
  activities: objectReducer("activities"),
  softwareInventory: objectReducer("software-inventory"),
  patchInventory: objectReducer("patch-inventory"),
  patchSoftwareInventory: objectReducer("patch-software-inventory"),
  needsReboot: objectReducer("needs-reboot"),
  devicesWithInstallIssues: objectReducer("devices-with-install-issues"),
  devicesRequiringInstallReboot: objectReducer("devices-requiring-install-reboot"),
  devicesRequiringRebootUpgradeEngine: objectReducer("devices-requiring-reboot-upgrade-engine"),
  devicesRequiringRebootDisinfect: objectReducer("devices-requiring-reboot-disinfect"),
  loggedInUser: objectReducer("logged-in-user"),
  domain: objectReducer("domain"),
  dnsServer: objectReducer("dns-server"),
  gatewayIp: objectReducer("gateway-ip"),
  ipAddress: objectReducer("ip-address"),
  publicIp: objectReducer("public-ip"),
  notes: objectReducer("notes"),
  nodeReliabilityUptime: objectReducer("node-reliability-uptime"),
  nodeReliabilityLastUptime: objectReducer("node-reliability-last-uptime"),
  nodeReliabilityOffline: objectReducer("node-reliability-offline"),
  anyConditionTriggered: objectReducer("any-condition-triggered"),
  conditionTriggered: objectReducer("condition-triggered"),
  nodeApprovalStatusPending: objectReducer("node-approval-status-pending"),
  nodeApprovalStatusApproved: objectReducer("node-approval-status-approved"),
  osBuildVersion: objectReducer("os-build-version"),
  osArchitecture: listReducer("os-architecture"),
  deviceThreats: listReducer("device-threats"),
  bitlockerStatus: listReducer("bitlocker-status"),
  fileVaultStatus: objectReducer("fileVault-status"),
  tpmStatus: listReducer("tpm-status"),
  maintenanceMode: listReducer("maintenance-mode"),
  computerSystemRoles: objectReducer("computer-system-roles"),
  customFields: listReducer("custom-fields"),
  lastPatchScan: objectReducer("last-patch-scan"),
  noPatchScan: objectReducer("no-patch-scan"),
  lastInstalledPatch: objectReducer("last-installed-patch"),
  osPatchingEnabled: objectReducer("os-patching-enabled"),
  windowsPatchingMode: listReducer("windows-patching-mode"),
  deviceRunningAction: listReducer("device-running-action"),
  expiredAppleEnrollmentProfileStatus: objectReducer("expired-apple-enrollment-profile-status"),
  mdmApnConnection: listReducer("mdm-apn-connection"),
  mdmAndroidEnterpriseConnection: listReducer("mdm-android-enterprise-connection"),
  softwarePatchingEnabled: objectReducer("software-patching-enabled"),
  osType: listReducer("os-type"),
  osMinorVersion: objectReducer("os-minor-version"),
  osMajorVersion: objectReducer("os-major-version"),
  osReleaseVersion: objectReducer("os-release-version"),
  warrantyStartDate: objectReducer("warranty-start-date"),
  warrantyEndDate: objectReducer("warranty-end-date"),
  manufacturerFulfillmentDate: objectReducer("manufacturer-fulfillment-date"),
  nmDelegate: objectReducer("nm-delegate"),
  osInstallDate: objectReducer("os-install-date"),
  ninjaBackupEnabled: objectReducer("ninja-backup-enabled"),
  ninjaBackupAgentInstalled: objectReducer("ninja-backup-agent-installed"),
  ninjaBackupPlanType: objectReducer("ninja-backup-plan-type"),
  vulnerabilityScanGroupName: listReducer("vulnerability-scan-group-name"),
  vulnerabilitySeverity: listReducer("vulnerability-severity"),
  vulnerabilityCveId: listReducer("vulnerability-cve-id"),
})
