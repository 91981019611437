import { memo, useCallback, useMemo } from "react"
import { ControlLabel } from "react-bootstrap"
import { find, omit } from "ramda"
import styled from "@emotion/styled"
import { $generateHtmlFromNodes } from "@lexical/html"
import { $getRoot } from "lexical"

import { Checkbox } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import showModal from "js/includes/common/services/showModal"
import { useForm } from "js/includes/common/hooks"
import Modal from "js/includes/components/Modal"
import {
  localized,
  localizationKey,
  capitalizeFirstLetter,
  validations,
  isFeatureEnabled,
} from "js/includes/common/utils"
import { StyledForm, StyledFormGroup, StyledCol, StyledFormControl } from "js/includes/components/Styled/Form"
import { StyledSpan } from "js/includes/components/Styled"
import { findNew } from "js/includes/ticketing/commonMethods"
import { StyledEditorWrapper } from "js/includes/ticketing/TriggerEditor/components/Styled"
import { Placeholders } from "js/includes/ticketing/TriggerEditor/components/Placeholders"
import { getCurrentUserOption } from "js/includes/ticketing/shared/components/AppUsersAndContactsDropdown"
import { additionalEmailDefaultOptions } from "js/includes/components/RuleEditor/utils"
import { UserEmailEditor } from "js/includes/ticketing/TriggerEditor/components/UserEmailEditor"
import { getUsersDeletedValidation, mapExistingUidValues } from "js/includes/ticketing/TriggerEditor/utils"
import { WYSIWYGEditor, WYSIWYGEditorReadOnly } from "js/includes/ticketing/editor/shared/components/WYSIWYGEditor"
import { WYSIWYGEditor as WYSIWYGEditorLegacy } from "js/includes/ticketing/editor/shared/components"
import { useEditor } from "js/includes/components/RichTextEditor/hooks/useEditor"

const StyledSubject = styled.span`
  font-weight: 600;
  margin-top: 8px;
`

const StyledContainer = styled.div`
  .inmodal {
    .modal-body {
      text-align: initial;
    }
  }
`

const EditorModal = memo(
  ({
    value,
    handleOnChange,
    editorInsertHtml,
    showCurrentUserOption,
    isWYSIWYGV3FeatureEnabled,
    unmount,
    metaData,
  }) => {
    const currentUserOption = useMemo(() => getCurrentUserOption(), [])
    const additionalDefaultOptions = useMemo(additionalEmailDefaultOptions, [])

    const { values, onChange, validation, validateForm } = useForm({
      fields: {
        to: {
          emails: value?.emails ?? [],
          uids: value?.uids ?? [],
          keys:
            value?.keys.map(key =>
              [currentUserOption, ...additionalDefaultOptions].find(
                option => key?.uid?.toLowerCase() === option?.uid?.toLowerCase(),
              ),
            ) ?? [],
        },
        subject: value?.subject ?? "",
        body: value?.body ?? "",
        allowResponse: value?.allowResponse ?? false,
      },
      validate: {
        to: ({ uids, keys, emails }) => {
          return getUsersDeletedValidation({
            metaData: metaData?.toUsers,
            metadataIdKey: "uid",
            valueToArrayConverter: valueArray => mapExistingUidValues(valueArray),
          })([...uids, ...keys, ...emails])
        },
        subject: validations.required,
        body: validations.required,
      },
    })

    function handleSave() {
      if (validateForm()) {
        editorInsertHtml(values.body)
        handleOnChange(
          omit(["to"], {
            ...values,
            ...values.to,
          }),
        )
        unmount()
      }
    }

    function handleToChange(selected) {
      const email = find(findNew, selected)
      if (email) {
        // Make sure is an email
        const { success } = validations.email(email.displayName)
        if (!success) return
      }

      const defaultValue = { keys: [], uids: [], emails: [] }

      const newToValues = selected.reduce((acc, option) => {
        if (option.isNew) {
          acc.emails = [...acc.emails, option]
        } else if (option.isCurrentUser || option.isDefaultOption) {
          acc.keys = [...acc.keys, option]
        } else {
          acc.uids = [...acc.uids, option]
        }
        return acc
      }, defaultValue)

      onChange("to", newToValues)
    }

    const onChangeEvent = useCallback(
      (editorState, editor) => {
        editorState.read(() => {
          const root = $getRoot()
          const text = root.getTextContent()
          onChange("body", text.trim().length ? $generateHtmlFromNodes(editor) : null)
        })
      },
      [onChange],
    )

    return (
      <StyledContainer>
        <StyledForm horizontal marginTop="0">
          <Modal
            bsSize="large"
            title={localizationKey("Edit")}
            close={unmount}
            saveText={localizationKey("Apply")}
            save={handleSave}
          >
            <StyledFormGroup validationState={validation.message.to ? "error" : null} marginBottom={1}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel} id="to-label">
                {capitalizeFirstLetter(localized("to"))} <sup>*</sup>
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <UserEmailEditor
                  showCurrentUserOption={showCurrentUserOption}
                  value={[...values.to.keys, ...values.to.emails, ...values.to.uids]}
                  handleOnChange={handleToChange}
                  additionalDefaultOptions={additionalDefaultOptions}
                  ariaAttributes={{ "aria-labelledby": "to-label" }}
                />
                {validation.message.to && <em className="invalid">{validation.message.to}</em>}
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup validationState={validation.success.subject === false ? "error" : null} marginBottom={1}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Subject")} <sup>*</sup>
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <StyledFormControl
                  maxLength="100"
                  value={values.subject}
                  onChange={e => onChange("subject", e.target.value)}
                />
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup validationState={validation.success.body === false ? "error" : null} marginBottom={1}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Body")} <sup>*</sup>
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                {isWYSIWYGV3FeatureEnabled ? (
                  <WYSIWYGEditor
                    htmlParserPluginProps={{ initialHTML: values.body }}
                    onChangePluginProps={{
                      onChange: onChangeEvent,
                    }}
                    validationProps={{
                      errorMessage: validation.message.body,
                    }}
                    imagePluginProps={{ excluded: true }}
                    attachmentPluginProps={{ excluded: true }}
                    htmlInjectorPluginProps={{ included: true }}
                  />
                ) : (
                  <WYSIWYGEditorLegacy
                    editorData={{
                      htmlBody: values.body,
                    }}
                    allowAttachments={false}
                    allowInlineImages={false}
                    hasError={validation.success.body === false}
                    onChange={({ html, text }) => onChange("body", text.trim().length ? html : null)}
                  />
                )}
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup marginBottom={2}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Placeholders")}
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <Placeholders extended withCurrentUserPlaceholders={showCurrentUserOption} />
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup flex={1} display="flex" flexDirection="row" textAlign="left">
              <StyledCol xs={12} display="flex" alignItems="center" componentClass={ControlLabel}>
                <Checkbox
                  name="allowResponse"
                  checked={values.allowResponse}
                  onChange={() => onChange("allowResponse", !values.allowResponse)}
                />
                <StyledSpan marginLeft={sizer(2)}>{localized("Allow email response by recipient")}</StyledSpan>
              </StyledCol>
            </StyledFormGroup>
          </Modal>
        </StyledForm>
      </StyledContainer>
    )
  },
)

export const EmailEditor = memo(({ value, values, handleOnChange, showCurrentUserOption = true, metaData }) => {
  const [editor, { editorInsertHtml }] = useEditor()

  const subject = value?.subject ?? localized("Subject")
  const htmlBody = useMemo(() => value?.body ?? localized("Body"), [value?.body])
  const isWYSIWYGV3FeatureEnabled = isFeatureEnabled("wysiwyg_v3")

  return (
    <StyledEditorWrapper
      onClick={() =>
        showModal(
          <EditorModal
            {...{
              editorInsertHtml,
              value,
              values,
              handleOnChange,
              showCurrentUserOption,
              isWYSIWYGV3FeatureEnabled,
              metaData,
            }}
          />,
          { withProvider: true },
        )
      }
    >
      <StyledSubject className="break-word">{subject}</StyledSubject>
      {isWYSIWYGV3FeatureEnabled ? (
        <WYSIWYGEditorReadOnly htmlParserPluginProps={{ initialHTML: htmlBody, updateOnChange: true }} />
      ) : (
        <WYSIWYGEditorLegacy readOnly editor={editor} editorData={{ htmlBody }} />
      )}
    </StyledEditorWrapper>
  )
})
