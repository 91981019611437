import { T, always, cond } from "ramda"
import { RegularInfoCircleIconLight } from "@ninjaone/icons"
import { cellTypes } from "@ninjaone/components/src/DataTable"
import { dateTime, localizationKey, localized, isFeatureEnabled, localizedWith } from "js/includes/common/utils"
import { getExtensionIconName, replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import { getReadableBytes } from "js/includes/common/_conversions"
import {
  getPlanTypeLabel,
  getScheduleBackupDescription,
  isAnyImagePlan,
  isArrowImagePlan,
  isLegacyImagePlan,
  planTypesMap,
} from "js/includes/common/backup"
import { CLOUD, HYBRID, LOCAL, destinationTokens } from "js/includes/editors/Policy/Sections/Backups/common/destination"
import VolumePopoverContent from "js/includes/systemDashboard/backups/Manage/components/VolumePopoverContent"

const getPlanIcons = cond([
  [({ type }) => type === planTypesMap.FILE_FOLDER, always("FileFolderIconSvg")],
  [({ type }) => isAnyImagePlan(type), always("ImageVolumeIconSvg")],
  [T, always("ImageVolumeIconSvg")],
])

const getNameColumn = (headerText, cellProps = {}) => ({
  id: "name",
  accessor: "name",
  Header: headerText,
  getIconName: cond([
    [({ plan }) => plan, getPlanIcons],
    [({ isPointInTime }) => isPointInTime, always("HistoryIconSolid")],
    [({ volume }) => volume, always("ImageVolumeIconSvg")],
    [({ folders }) => folders, always("FoldersIconSolid")],
    [
      ({ type, folder }) => type === "folder" || type === "mountedFolder",
      ({ expanded }) => (expanded ? "FolderOpenIconSolid" : "FolderIconSolid"),
    ],
    [T, ({ path, eventKey }) => getExtensionIconName(path || eventKey)],
  ]),
  priorityWidth: true,
  getCellInlineTags: ({ planDeleted, missing, removed }) => {
    const tags = []

    if (planDeleted) {
      tags.push({
        label: localized("Plan Deleted"),
        id: "deleted",
        tooltipLabel: localizedWith("Backup data is <%italicText>not<%> deleted", {
          italicText: ({ localizedText }) => <i>{localizedText}</i>,
        }),
      })
    }

    if (missing) {
      tags.push({
        label: localized("Missing"),
        id: "missing",
        tooltipLabel: localized("No longer present on backup target"),
      })
    }

    if (removed) {
      tags.push({ label: localized("Removed"), id: "removed", tooltipLabel: localized("Removed from backup plan") })
    }

    return tags.length ? tags : null
  },
  getCellTypeProps: () => ({
    type: cellTypes.BUTTON_LINK,
  }),
  disableSortBy: true,
  ...cellProps,
})

const getSizeColumn = headerToken => ({
  id: "size",
  accessor: ({ size }) => getReadableBytes(size),
  Header: localized(headerToken),
  disableSortBy: true,
})

const getActualSizeColumn = headerToken => ({
  id: "actualSize",
  accessor: ({ actualSize }) => getReadableBytes(actualSize),
  Header: localized(headerToken),
  disableSortBy: true,
  tooltipToken: localized("Amount of files with changed data"),
})

const getBackupStorageUsedColumn = headerToken => ({
  id: "Backup Storage Used",
  accessor: ({ localSize = 0, cloudSize = 0, destination }) =>
    localized(destinationSizeToken[destination], {
      localSize: getReadableBytes(localSize),
      cloudSize: getReadableBytes(cloudSize),
    }),
  Header: localized(headerToken),
  disableSortBy: true,
})

export const getPlansColumns = () => [
  getNameColumn(localized("Plan Name")),
  {
    id: "lastSuccess",
    accessor: ({ lastSuccess }) => (lastSuccess ? dateTime(lastSuccess) : null),
    Header: localized("Last success"),
    disableSortBy: true,
    getCellTypeProps: () => ({
      type: cellTypes.DATE,
    }),
  },
  {
    id: "schedule",
    accessor: rowData => (rowData.schedule ? getScheduleBackupDescription(rowData) : "-"),
    Header: localized("Schedule"),
    disableSortBy: true,
  },
  {
    id: "planType",
    accessor: ({ type }) => getPlanTypeLabel(type),
    Header: localized("Type"),
    disableSortBy: true,
    secondaryIconProps: {
      getIconTooltipLabel: ({ type }) =>
        isLegacyImagePlan(type) &&
        isFeatureEnabled("backup_arrow_image") &&
        localized("Refers to original Image Backup plan type"),
      getIconName: ({ type }) =>
        isLegacyImagePlan(type) && isFeatureEnabled("backup_arrow_image") && "RegularInfoCircleIconLight",
    },
  },
  {
    id: "destination",
    accessor: ({ destination }) => localized(destinationTokens[destination]),
    Header: localized("Destination"),
    disableSortBy: true,
  },
  {
    id: "machineStorageUsed",
    accessor: ({ storage }) => getReadableBytes(storage?.volumes),
    Header: localized("Machine storage used"),
    disableSortBy: true,
    getCellTypeProps: () => ({
      type: cellTypes.NUMBER,
    }),
  },
  {
    id: "backupStorageUsed",
    accessor: ({ storage, destination }) =>
      storage
        ? localized(destinationSizeToken[destination], {
            localSize: getReadableBytes(storage?.local ?? 0),
            cloudSize: getReadableBytes(storage?.cloud ?? 0),
          })
        : null,
    Header: localized("Backup storage used"),
    disableSortBy: true,
    getCellTypeProps: () => ({
      type: cellTypes.NUMBER,
    }),
  },
]

const destinationSizeToken = {
  [HYBRID]: localizationKey("{{localSize}} Local, {{cloudSize}} Cloud"),
  [LOCAL]: localizationKey("{{localSize}} Local"),
  [CLOUD]: localizationKey("{{cloudSize}} Cloud"),
}

export const getVolumesColumns = type => [
  getNameColumn(
    localized("Volume name"),
    isArrowImagePlan(type)
      ? {
          getCellTypeProps: originalRow => {
            return {
              type: cellTypes.STICKY_POPOVER,
              props: {
                PopoverContentComponent: <VolumePopoverContent {...originalRow} />,
                iconTrigger: <RegularInfoCircleIconLight size="sm" />,
                keepPopoverOpenOnHover: true,
                renderTextAsLink: true,
              },
            }
          },
        }
      : {},
  ),
  ...(!isArrowImagePlan(type)
    ? [
        {
          id: "id",
          accessor: ({ id }) => {
            const guidMatched = id?.match?.(/{(.*?)}/)
            return guidMatched ? guidMatched[1] : localized("Unknown")
          },
          Header: localized("GUID"),
          isCopyable: true,
          disableSortBy: true,
        },
        {
          id: "location",
          accessor: "location",
          Header: localized("Location"),
          disableSortBy: true,
        },
        {
          id: "fileSystem",
          accessor: "fileSystem",
          Header: localized("File system"),
          disableSortBy: true,
        },
        {
          id: "partitionStyle",
          accessor: "partitionStyle",
          Header: localized("Disk layout"),
          disableSortBy: true,
        },
      ]
    : []),
  {
    id: "lastRevision",
    accessor: ({ lastRevision }) => (lastRevision ? dateTime(lastRevision) : null),
    Header: localized("Latest revision"),
    disableSortBy: true,
  },
  ...(isArrowImagePlan(type)
    ? [
        {
          id: "volumeUsed",
          accessor: ({ used }) => getReadableBytes(used),
          Header: localized("Volume used"),
          disableSortBy: true,
        },
        {
          id: "volumeCapacity",
          accessor: ({ capacity }) => getReadableBytes(capacity),
          Header: localized("Volume capacity"),
          disableSortBy: true,
        },
        getBackupStorageUsedColumn(localizationKey("Backup storage used")),
        {
          id: "deduplication",
          accessor: ({ deduplication }) => getReadableBytes(deduplication),
          Header: localized("Deduplication"),
          disableSortBy: true,
          tooltipToken: localizationKey("Amount of storage saved by deduplication of identical blocks of data"),
        },
      ]
    : []),

  ...(!isArrowImagePlan(type) ? [getSizeColumn(localizationKey("Total Storage"))] : []),
]

const methodNameTokens = {
  NON_BLD: localizationKey("Full"),
  FULL: localizationKey("Full"),
  DELTA: localizationKey("Incremental"),
}

export const getRevisionsColumns = ({ planType, destination } = {}) => [
  getNameColumn(localized("Job Run")),
  {
    id: "jobCompleted",
    accessor: ({ jobCompleted }) => (jobCompleted ? dateTime(jobCompleted) : null),
    Header: localized("Completed"),
    disableSortBy: true,
  },
  ...(!isArrowImagePlan(planType)
    ? [
        {
          id: "bldType",
          accessor: ({ bldType }) => localized(methodNameTokens[bldType] ?? localizationKey("Unknown")),
          Header: localized("Method"),
          disableSortBy: true,
        },
      ]
    : []),
  getActualSizeColumn(localizationKey("Data Scanned")),
  {
    id: "backupSize",
    accessor: ({ backupSize }) => getReadableBytes(backupSize),
    Header: localized("Data Stored"),
    disableSortBy: true,
    secondaryIconProps: {
      getIconName: ({ rollupSize }) => (rollupSize ? "RegularInfoCircleIconLight" : null),
      getIconTooltipLabel: ({ rollupSize }) =>
        rollupSize
          ? localized("Includes {{rollupSize}} rollups of prior revision data", {
              rollupSize: getReadableBytes(rollupSize),
            })
          : null,
    },
    tooltipToken: localized("Size of changed data after compression"),
  },
  ...(isArrowImagePlan(planType) && destination === HYBRID
    ? [
        {
          id: "pointExistsIn",
          accessor: ({ onS3, onNas }) => {
            let pointLocations = []
            if (onNas) pointLocations.push(localized("Local"))
            if (onS3) pointLocations.push(localized("Cloud"))

            return pointLocations.join(", ")
          },
          Header: localized("Point exists in"),
          disableSortBy: true,
          tooltipToken: localized("Storage destination of this backup point"),
        },
      ]
    : []),
]

export const getFileFoldersColumns = (globalFilter, separator) => [
  getNameColumn(localized("Name")),
  ...(globalFilter
    ? [
        {
          id: "path",
          accessor: ({ path }) => replaceSeparatorAndRemoveTrailingSlash(path, separator),
          Header: localized("Path"),
          disableSortBy: true,
        },
      ]
    : [
        {
          id: "latestRevision",
          accessor: ({ latestRevisionCreatedAt }) =>
            latestRevisionCreatedAt ? dateTime(latestRevisionCreatedAt) : null,
          Header: localized("Latest Revision"),
        },
        {
          id: "originalSize",
          accessor: ({ originalSize }) => getReadableBytes(originalSize),
          Header: localized("Original Size"),
          disableSortBy: true,
        },
        getActualSizeColumn(localizationKey("Actual Size")),
      ]),
]

export const getMountedContentColumns = () => [
  getNameColumn(localized("Name")),
  {
    id: "modified",
    accessor: ({ modified }) => (modified ? dateTime(modified) : null),
    Header: localized("Modified"),
    disableSortBy: true,
  },
  getSizeColumn(localizationKey("Size")),
]
