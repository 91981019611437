import { useMemo } from "react"
import { connect } from "react-redux"
import { startsWith } from "ramda"
import {
  GraduationHatLight,
  MegaphoneIconLight,
  MemoIconLight,
  QuestionCircleIconLight,
  RoadLightIcon,
  FileCodeIconLight,
} from "@ninjaone/icons"
import {
  NINJAONE_ZENDESK_BASE_URL,
  getNinjaBranding,
  isFeatureEnabled,
  isRmmServiceLocation,
  localizationKey,
} from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import RowItem from "../RowItem"
import SubmitTicketRowItem from "../SubmitTicketRowItem"
import SuggestedArticles from "../SuggestedArticles/SuggestedArticles"
import WorkrampRowItem from "../WorkrampRowItem"

export const helpSettingsIconMapper = {
  [localizationKey("Dojo / Help center")]: <QuestionCircleIconLight />,
  "NinjaOne Academy": <GraduationHatLight />,
  [localizationKey("News")]: <MegaphoneIconLight />,
  [localizationKey("Release notes")]: <MemoIconLight />,
  [localizationKey("Product roadmap")]: <RoadLightIcon />,
  [localizationKey("API documentation")]: <FileCodeIconLight />,
}

const HelpDefault = ({
  helpSettings: _helpSettings,
  handleClose,
  handleChangeView,
  activeRoute,
  showHelpSubmitTicket,
  showHelpNinjaArticles,
  showNinjaOneWorkrampAcademy,
}) => {
  const helpSettings = useMemo(
    () => (isRmmServiceLocation() ? _helpSettings : getNinjaBranding().general.helpSettings),
    [_helpSettings],
  )

  const options = useMemo(
    () =>
      helpSettings.topics.map(({ topic, token, action, ...restProps }, index) => ({
        action: () => (typeof action === "function" ? action() : window.open(action, "_blank", "noopener noreferrer")),
        isExternalLink: true,
        icon: token && !isRmmServiceLocation() ? helpSettingsIconMapper[token] : null,
        id: `help-settings-${token ? token : index}`,
        topic,
        zendeskUrl: typeof action === "string" && startsWith(NINJAONE_ZENDESK_BASE_URL, action) ? action : null,
        ...restProps,
      })),
    [helpSettings],
  )

  return (
    <>
      {showHelpNinjaArticles && <SuggestedArticles />}
      <Flex flexDirection="column">
        {showHelpSubmitTicket && <SubmitTicketRowItem {...{ handleChangeView }} />}
        {isFeatureEnabled("ninja_academy") && showNinjaOneWorkrampAcademy && (
          <WorkrampRowItem {...{ handleChangeView }} />
        )}
        {options.map(({ id, action, zendeskUrl, ...restProps }, index) => (
          <RowItem
            {...{
              key: id,
              id,
              zendeskUrl,
              onClick: () => {
                const keepViewOpen = action()
                if (!keepViewOpen) {
                  handleClose()
                }
              },
              ...restProps,
            }}
          />
        ))}
      </Flex>
    </>
  )
}

export default connect(({ websiteBranding }) => ({
  helpSettings: websiteBranding.brandingNode.general.helpSettings,
}))(HelpDefault)
