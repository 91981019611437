import { head } from "ramda"
import styled from "@emotion/styled"
import { Select, Text, Input, Checkbox, AlertMessage, Tooltip } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { localizationKey, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import {
  DECIMAL_INPUT_EXCLUDED_SYMBOLS,
  INTEGER_INPUT_EXCLUDED_SYMBOLS,
  formatIntegerValue,
  formatDecimalValue,
  clearIllegalCharacters,
} from "js/includes/configuration/scripting/ScriptsSubTabModel/common"
import { DateTimePicker } from "js/includes/components/DateTimePicker"
import { getErrorBannerMessages } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/scriptVariableUtils.js"

const StyledScriptVariablesContainer = styled.div`
  height: 400px;
  padding: ${sizer(0, 2, 2, 2)};
  border-radius: ${sizer(0, 0, 1, 1)};
  background-color: ${({ theme }) => theme.color.black["005"]};
  overflow-y: auto;
`

const StyledContainerHeader = styled.div`
  height: ${sizer(4)};
  border-radius: ${sizer(1, 1, 0, 0)};
  background-color: ${({ theme }) => theme.color.black["005"]};
`

const RunModalScriptVariables = ({ scriptVariables = [], onChange, validation, values }) => {
  const onScriptVariableChange = change => {
    const [key, value] = head(Object.entries(change))
    const scriptVariablesKey = `scriptVariables.${key}`
    onChange(scriptVariablesKey, value)
  }

  const ScriptLabelComponent = ({ name, type, description, required }) => (
    <Flex gap={sizer(2)} alignItems="center">
      <Text size="sm">
        {name}
        {type !== "CHECKBOX" && required ? " *" : ""}
      </Text>
      {description && (
        <Tooltip label={localized(description)}>
          <RegularInfoCircleIcon size={"sm"} />
        </Tooltip>
      )}
    </Flex>
  )

  const getScriptVariableField = variable => {
    let field = <></>
    const fieldName = variable.calculatedName
    const defaultValue = values.scriptVariables[fieldName]
    switch (variable.type) {
      case "TEXT":
        field = (
          <Input
            value={defaultValue}
            onChange={e => {
              onScriptVariableChange({ [fieldName]: clearIllegalCharacters(e.target.value) })
            }}
            placeholder={localized("Add Value")}
            errorMessage={validation.message?.scriptVariables?.[fieldName]}
          />
        )
        break
      case "INTEGER":
        field = (
          <Input
            value={defaultValue}
            onChange={e => {
              onScriptVariableChange({ [fieldName]: formatIntegerValue(e.target.value) })
            }}
            placeholder={localized("Enter Integer Value")}
            type="number"
            onKeyDown={e => {
              if (INTEGER_INPUT_EXCLUDED_SYMBOLS.includes(e.key)) {
                e.preventDefault()
              }
            }}
            onPaste={e => {
              e.preventDefault()
              const defaultValue = formatIntegerValue(e.clipboardData?.getData("Text"))
              onScriptVariableChange({ [fieldName]: defaultValue })
            }}
            errorMessage={validation.message?.scriptVariables?.[fieldName]}
          />
        )
        break
      case "DECIMAL":
        field = (
          <Input
            value={defaultValue}
            step="0.1"
            onChange={e => {
              onScriptVariableChange({ [fieldName]: formatDecimalValue(e.target.value) })
            }}
            placeholder={localized("Enter Decimal Value")}
            type="number"
            onKeyDown={e => {
              if (DECIMAL_INPUT_EXCLUDED_SYMBOLS.includes(e.key)) {
                e.preventDefault()
              }
            }}
            onPaste={e => {
              e.preventDefault()
              const defaultValue = formatDecimalValue(e.clipboardData?.getData("Text"))
              onScriptVariableChange({ [fieldName]: defaultValue })
            }}
            errorMessage={validation.message?.scriptVariables?.[fieldName]}
          />
        )
        break
      case "CHECKBOX":
        field = (
          <Box margin={sizer(3, 0, 0, 0)}>
            <Checkbox
              label={ScriptLabelComponent(variable)}
              checked={!!defaultValue}
              onChange={() => onScriptVariableChange({ [fieldName]: !defaultValue })}
              fontWeight={500}
            />
          </Box>
        )
        break
      case "DATE":
        field = (
          <DateTimePicker
            className="dateTimePicker"
            value={defaultValue}
            onChange={dateTime => onScriptVariableChange({ [fieldName]: String(dateTime) })}
            validationError={validation.message?.scriptVariables?.[fieldName]}
            mandatory={variable.required}
            allowPastDates
            isoFormat
          />
        )
        break
      case "DATETIME":
        field = (
          <DateTimePicker
            className="dateTimePicker"
            timeFormat
            value={defaultValue}
            onChange={dateTime => onScriptVariableChange({ [fieldName]: String(dateTime) })}
            validationError={validation.message?.scriptVariables?.[fieldName]}
            mandatory={variable.required}
            allowPastDates
            isoFormat
          />
        )
        break
      case "DROPDOWN":
        const options = variable.valueList?.map(option => ({ value: option, labelText: option }))
        field = (
          <Select
            matchWidth
            options={options}
            value={defaultValue}
            onChange={selected => onScriptVariableChange({ [fieldName]: selected })}
            labelRenderer={() =>
              defaultValue ? (
                <Text size="sm">{defaultValue}</Text>
              ) : (
                <Text size="sm" color={{ code: "black", shade: "065" }} token={localizationKey("Select Value")} />
              )
            }
            errorMessage={validation.message?.scriptVariables?.[fieldName]}
          />
        )
        break
      case "IPADDRESS":
        field = (
          <Input
            value={defaultValue}
            data-testid={fieldName}
            onChange={e => {
              onScriptVariableChange({ [fieldName]: e.target.value.replace(/[^\d.]/gi, "") })
            }}
            placeholder={localized("Enter IP Address Value")}
            errorMessage={validation.message?.scriptVariables?.[fieldName]}
          />
        )
        break
      default:
        break
    }

    const { name, type, description, required } = variable
    return (
      <Box key={fieldName} marginBottom={sizer(3)}>
        {variable.type !== "CHECKBOX" && <ScriptLabelComponent {...{ name, type, description, required }} />}
        {field}
      </Box>
    )
  }

  const currentErrorMessage = head(getErrorBannerMessages(validation.message))

  return (
    <>
      {scriptVariables.length > 0 && (
        <>
          <StyledContainerHeader />
          <StyledScriptVariablesContainer>{scriptVariables.map(getScriptVariableField)}</StyledScriptVariablesContainer>
          <Box marginTop={3}>
            {currentErrorMessage && <AlertMessage variant="danger">{localized(currentErrorMessage)}</AlertMessage>}
          </Box>
        </>
      )}
    </>
  )
}

export default RunModalScriptVariables
