import qs from "qs"
import { values } from "ramda"
import pathParse from "path-parse"
import {
  fetchJson,
  localized,
  user,
  localizationKey,
  reportErrorAndShowMessage,
  isNotNilOrEmpty,
} from "js/includes/common/utils"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import { getLocalizedCommonFolderName } from "js/includes/editors/Policy/Common/util"
import { getFileFoldersActions } from "./actions"
import { getFileFoldersColumns } from "./columns"

const getRootFolders = async ({
  currentDir: { name: planName, planId, planDeleted, path: parentPath, folder, destination, backupPermissions },
  fetchingParams: { pageIndex, pageSize },
  separator,
  node,
  isFileFolderBackupAllowed,
}) => {
  const folderContentResponse = await fetchJson(
    `/backup/lockhart/nodes/${node.id}/plans/${planId}/folders?${qs.stringify({
      page: pageIndex,
      size: pageSize,
    })}`,
  )
  const isUserRestricted = !user("canViewAndRestoreBackupData") && !isFileFolderBackupAllowed

  const folders = folderContentResponse.reduce(
    (acc, { id, name, path, type, deleted, status, cfid, latestRevisionCreatedAt }) => {
      const { commonFolders, rootFolders } = acc

      if (type === "COMMON_FOLDER") {
        const commonFolderPath = path + separator + cfid
        commonFolders[cfid] = commonFolders[cfid] || {
          id,
          cfid,
          node,
          planId,
          planName,
          parentPath,
          folder: true,
          restricted: isUserRestricted,
          common: true,
          root: true,
          type: "folder",
          missing: deleted,
          planDeleted,
          removed: status === "DELETED",
          path: commonFolderPath,
          name: getLocalizedCommonFolderName(cfid),
          content: [],
          status,
          destination,
          latestRevisionCreatedAt,
        }
        const commonFolderData = {
          id,
          cfid,
          node,
          path,
          planId,
          deleted,
          planName,
          parentPath,
          root: true,
          folder: true,
          name: status === "DEFINED" ? localized("N/A") : replaceSeparatorAndRemoveTrailingSlash(path || "", separator),
          type: "folder",
          missing: deleted,
          planDeleted,
          removed: status === "DELETED",
          backupPermissions,
          status,
          destination,
          latestRevisionCreatedAt,
        }

        commonFolders[cfid].content.push(commonFolderData)
      } else {
        const rootFolder = {
          id,
          node,
          planId,
          folder,
          restricted: isUserRestricted,
          deleted,
          planName,
          parentPath,
          root: true,
          name: replaceSeparatorAndRemoveTrailingSlash(path || "", separator),
          path: replaceSeparatorAndRemoveTrailingSlash(path || "", separator),
          type: "folder",
          missing: deleted,
          planDeleted,
          removed: status === "DELETED",
          backupPermissions,
          status,
          destination,
          latestRevisionCreatedAt,
        }

        rootFolders.push(rootFolder)
      }

      return acc
    },
    {
      commonFolders: {},
      rootFolders: [],
    },
  )

  const commonFolders = values(folders.commonFolders)
  const rootFolders = folders.rootFolders

  return {
    data: [...commonFolders, ...rootFolders],
  }
}

export const getSearchResults = async ({
  currentDir: {
    planName,
    planId,
    planDeleted,
    path: parentPath,
    id: folderId,
    common,
    content,
    backupPermissions,
    destination,
    previousDir,
  },
  fetchingParams: { pageSize, pageIndex, globalFilter },
  separator,
  node,
}: fetchDataProps) => {
  const { results = [], totalElements, totalPages } =
    (await fetchJson(
      `/backup/lockhart/nodes/${node.id}/plans/${planId}/search?${qs.stringify({
        searchTerm: globalFilter,
        page: pageIndex,
        size: pageSize,
      })}`,
    )) ?? {}

  const searchResults = results.map(({ id, name, path, type, deleted, backupSize, size }) => {
    const isFolder = type === "FOLDER"
    const searchPath = replaceSeparatorAndRemoveTrailingSlash(path.replace(`${parentPath}/`, ""), separator)

    return {
      id,
      node,
      planId,
      deleted,
      planName,
      parentPath,
      folder: true,
      planDeleted,
      actualSize: backupSize,
      originalSize: size,
      type: isFolder ? "folder" : "file",
      name: isFolder ? pathParse(path).base : name,
      path,
      searchPath: isFolder ? searchPath : searchPath + separator + name,
      backupPermissions,
      destination,
    }
  })

  return { data: searchResults, pageCount: totalPages, totalCount: totalElements }
}

const fetchData = fetchProps => {
  const {
    fetchingParams: { globalFilter },
  } = fetchProps

  const fetchFunction = isNotNilOrEmpty(globalFilter) ? getSearchResults : getRootFolders
  return fetchFunction(fetchProps)
}

const onError = (error, { name }) =>
  reportErrorAndShowMessage(
    error,
    localizationKey("There was an error fetching folders for backup plan {{backupPlanName}}"),
    { backupPlanName: name },
  )

export const getFileFoldersHandler = ({
  isFileFolderBackupAllowed,
  fetchingParams: { globalFilter } = {},
  separator,
}) => ({
  columns: getFileFoldersColumns(globalFilter, separator),
  actions: getFileFoldersActions({ isFileFolderBackupAllowed }),
  fetchData,
  onError,
  searchable: true,
})
