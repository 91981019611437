import { memo } from "react"
import moment from "moment"
import { Input } from "@ninjaone/components"
import { CalendarIcon } from "@ninjaone/icons"
import { localized, getDateTimeLocale, serverScheduleDateFormat, dateTimeFormat } from "js/includes/common/utils"
import { StyledDateTime } from "js/includes/components/Styled/Form"

const yesterday = moment().subtract(1, "day")
const validDate = function(current, allowPastDates) {
  return allowPastDates || current.isAfter(yesterday)
}

export const DateTimePicker = memo(
  ({
    timeFormat = false,
    isoFormat = false,
    name,
    value,
    onChange,
    mandatory,
    validation,
    validationError,
    allowPastDates = false,
  }) => {
    const handleISODateChange = dateTime => {
      if (moment.isMoment(dateTime)) {
        return onChange(dateTime.toISOString(true))
      }
    }

    const handleLocaleDateChange = dateTime => {
      const date = getDateTimeLocale(dateTime)
      if (moment.isMoment(dateTime) && date.isValid()) {
        return onChange(date.format(timeFormat ? dateTimeFormat : serverScheduleDateFormat))
      }
    }

    const handleDateChange = dateTime => {
      return isoFormat ? handleISODateChange(dateTime) : handleLocaleDateChange(dateTime)
    }

    return (
      <StyledDateTime
        closeOnSelect
        width="100%"
        timeFormat={timeFormat}
        isValidDate={current => validDate(current, allowPastDates)}
        value={value ? moment(value) : null}
        onChange={handleDateChange}
        renderInput={(inputProps, openCalendar) => {
          const { value, onChange } = inputProps
          return (
            <Input
              {...{
                placeholder: timeFormat ? localized("Select Date and Time") : localized("Select Date"),
                value,
                onChange,
                onFocus: openCalendar,
                minWidth: "auto",
                RightSideIcon: <CalendarIcon />,
                ...(mandatory && { errorMessage: validationError || validation?.message[name] }),
              }}
            />
          )
        }}
      />
    )
  },
)
