import React, { useLayoutEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import {
  getCountries,
  isPossiblePhoneNumber,
  getCountryCallingCode,
  default as ReactPhoneInput,
  parsePhoneNumber,
} from "react-phone-number-input/input"
import countryNames from "react-phone-number-input/locale/en"

import tokens from "@ninjaone/tokens"
import { isRequiredIf } from "@ninjaone/utils"

import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Input from "./Input"
import Body from "./Typography/Body"
import SearchableSelect from "./SearchableSelect/SearchableSelect"

const CountrySelectorContainer = styled(Box)`
  [data-ninja-searchable-select] {
    border: none;
    // Adding !important to override the default styles for the SearchableSelect
    background: transparent !important;

    gap: 0;
    padding: 0;

    padding-left: ${tokens.spacing[2]};

    // Adding !important to override the default styles for the arrow down icon
    svg {
      width: 9px !important;
    }

    & > div {
      margin-right: ${tokens.spacing[2]};
      max-width: unset;
    }
  }
`

const VerticalDivider = styled.div`
  height: 22px;
  width: 1px;

  margin-right: ${tokens.spacing[2]};

  background-color: ${({ theme }) => theme.colorBorderWeak};
`

const StyledInput = styled(Input)`
  &:disabled {
    &::placeholder {
      color: ${({ theme }) => theme.colorTextDisabled};
    }
  }
`

const defaultCountryId = "US"
const defaultCountryCodeWidth = "24px"
const spacingBetweenCountryCodeAndInput = 76

const getFlagUrl = country => `https://resources.ninjarmm.com/webapp/img/country-flag-icons/3x2/${country}.svg`

const flagImgStyles = {
  // Our min token value for the border-radius is 4px.
  borderRadius: "2px",
  width: "21px",
  objectFit: "cover",
  height: "14px",
}

export default function PhoneInput({
  ariaLabel,
  disabled,
  labelText,
  onChange,
  tooltipRenderer,
  tooltipText,
  value: controlledValue,
  defaultValue,
  errorMessage,
}) {
  const countryCodeEl = useRef()
  const inputEl = useRef()

  const [internalValue, setInternalValue] = useState(defaultValue)
  const [internalSelectedCountryId, setInternalSelectedCountryId] = useState(() => {
    return controlledValue ? parsePhoneNumber(controlledValue)?.country : defaultCountryId
  })
  const [selectedCountryCodeElWidth, setSelectedCountryCodeElWidth] = useState(defaultCountryCodeWidth)

  const value = controlledValue || internalValue
  const selectedCountryId = internalSelectedCountryId

  useLayoutEffect(() => {
    setSelectedCountryCodeElWidth(`${countryCodeEl.current.offsetWidth + spacingBetweenCountryCodeAndInput}px`)
  }, [selectedCountryId])

  const parsedCountries = getCountries().map(country => ({
    value: country,
    labelText: `${localized(countryNames[country])} (+${getCountryCallingCode(country)})`,
    icon: (
      <img
        {...{
          loading: "lazy",
          src: getFlagUrl(country),
          style: flagImgStyles,
          alt: localized("{{country}} flag", { country: localized(countryNames[country]) }),
        }}
      />
    ),
  }))

  const handleChangeValue = newValue => {
    const parsedNewValue = newValue || `+${getCountryCallingCode(selectedCountryId)}`
    const isValid = isPossiblePhoneNumber(parsedNewValue, selectedCountryId)

    if (!controlledValue) {
      setInternalValue(parsedNewValue)
    }

    onChange(parsedNewValue, isValid)
  }

  const handleChangeCountry = countryId => {
    setInternalSelectedCountryId(countryId)

    // When the user changes the selected country, the phone number must be reset.
    onChange(`+${getCountryCallingCode(countryId)}`)

    if (!controlledValue) {
      setInternalValue("")
    }

    if (inputEl?.current) {
      inputEl?.current?.focus()
    }
  }

  const getErrorMessage = () => {
    if (errorMessage) return errorMessage

    const isValid = isPossiblePhoneNumber(value || "", selectedCountryId)
    const countryCode = `+${getCountryCallingCode(selectedCountryId)}`

    if (!value || value === countryCode || isValid) {
      return
    }

    return localized("Invalid phone number")
  }

  return (
    <Box {...{ position: "relative" }}>
      <CountrySelectorContainer
        {...{
          alignItems: "center",
          display: "flex",
          left: "0",
          position: "absolute",
          top: labelText ? "25px" : "0",
          zIndex: "1",
        }}
      >
        <SearchableSelect
          {...{
            disabled,
            ariaLabel: localized("Select country"),
            onChange: handleChangeCountry,
            options: parsedCountries,
            selectedValueDisplay: "icon",
            value: selectedCountryId,
            popoverProps: { width: "300px", fullWidth: false },
          }}
        />

        <VerticalDivider />

        {/* The div is needed to get the size of the country code */}
        <div aria-hidden="true" ref={countryCodeEl}>
          <Body {...{ ...(disabled && { color: "colorTextDisabled" }) }}>
            +{getCountryCallingCode(selectedCountryId)}
          </Body>
        </div>
      </CountrySelectorContainer>

      <ReactPhoneInput
        ref={inputEl}
        {...{
          ariaLabel,
          country: selectedCountryId,
          disabled,
          errorMessage: getErrorMessage(),
          inputComponent: StyledInput,
          labelText,
          onChange: handleChangeValue,
          placeholder: localized("Enter phone number"),
          style: { paddingLeft: selectedCountryCodeElWidth },
          tooltipRenderer,
          tooltipText,
          value: value,
          color: disabled && "colorTextDisabled",
        }}
      />
    </Box>
  )
}

PhoneInput.propTypes = {
  /**
   * The accessible label for the component. Required when `label` is not
   * provided.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText"),
    "`ariaLabel` is required when `labelText` is not defined.",
  ),
  /**
   * Sets the disabled state of the component.
   */
  disabled: PropTypes.bool,
  /**
   * The text for the Label for the component.
   */
  labelText: PropTypes.string,
  /**
   * The callback function when the value changes. Returns the value and if the field is valid or not.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The Tooltip renderer function for the Label of the component. This prop is
   * intended to be used when providing `tooltipText` is not sufficent.
   */
  tooltipRenderer: PropTypes.func,
  /**
   * The text for the Tooltip in the Label of the the component.
   */
  tooltipText: PropTypes.string,
  /**
   * The controlled value of the component.
   */
  value: PropTypes.string,
  /**
   * The default value of the component. This is only used for the uncontrolled state
   */
  defaultValue: PropTypes.string,
  /**
   * Sets the error state of the component.
   */
  errorMessage: PropTypes.string,
}
