import { GraduationHatLight } from "@ninjaone/icons"
import { isRmmServiceLocation, localized, ninjaReportError, showErrorMessage } from "js/includes/common/utils"
import RowItem from "./RowItem"
import { getWorkrampLoginUrl } from "js/includes/common/client"

const WorkrampRowItem = () => {
  const handleClick = async e => {
    e.preventDefault()
    try {
      const { absoluteLoginUrl } = await getWorkrampLoginUrl()
      if (absoluteLoginUrl) {
        window.open(absoluteLoginUrl, "_blank", "noopener noreferrer")
      }
    } catch (error) {
      showErrorMessage(localized("Error opening NinjaOne Academy"))
      ninjaReportError(error)
    }
  }
  return (
    <RowItem
      {...{
        id: "ninjaone-academy",
        onClick: handleClick,
        topic: "NinjaOne Academy",
        icon: isRmmServiceLocation() ? null : <GraduationHatLight />,
      }}
    />
  )
}

export default WorkrampRowItem
