import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { typography } from "@ninjaone/tokens"
import { deprecated, isRequiredIf } from "@ninjaone/utils"
import Input from "./Input"

export const TEXTAREA_MAX_LENGTH = 250

const StyledTextArea = styled(Input)`
  min-width: ${({ minWidth }) => minWidth || "400px"};
  min-height: 80px;
  max-height: 400px;
  height: ${({ height }) => height};
  font-family: ${typography.fontFamily.primary};
  resize: ${({ resize }) => resize ?? "vertical"};

  &::placeholder {
    color: ${({ theme }) => theme.colorTextWeakest};
  }
`

const TextArea = props => {
  const { disableMaxLength, maxLength = TEXTAREA_MAX_LENGTH, useCharacterCount = true, height = "80px" } = props

  return (
    <StyledTextArea
      {...props}
      _isTextArea
      {...{ height }}
      {...(!disableMaxLength && { maxLength, useCharacterCount })}
    />
  )
}

TextArea.propTypes = {
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText") && !props.hasOwnProperty("labelToken"),
    "`ariaLabel` is required when `labelText` and `labelToken` are not defined.",
  ),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    deprecated(PropTypes.bool, "Use a string value for `errorMessage` instead."),
  ]),
  labelText: PropTypes.string,
  labelToken: PropTypes.string,
  LabelComponent: deprecated(PropTypes.elementType, "Use 'labelText' or 'labelToken' props instead."),
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  useCharacterCount: PropTypes.bool,
}

export default TextArea
