import styled from "@emotion/styled"

import { GripLinesIcon, RemoveIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { IconButton, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import DragableList from "js/includes/components/DragableList"
import { Box, Flex } from "js/includes/components/Styled"
import { isNotNilOrEmpty } from "js/includes/common/utils"

const StyledContainer = styled.div`
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundSubtle};

  .drag-label {
    padding: ${sizer(2, 2, 0, 2)};
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  .draggable-list {
    flex: auto;
    max-height: ${({ maxHeight }) => maxHeight ?? "initial"};
    min-height: ${({ minHeight }) => minHeight ?? "initial"};
    ${({ height }) => height && `height: ${height};`}
    padding: ${sizer(2)};
    overflow-y: auto;
    cursor: grab;
  }
`

const StyledFade = styled.div`
  position: sticky;
  bottom: 0;
  height: 0;
  overflow: visible;

  &::after {
    content: "";
    display: block;
    height: ${sizer(2)};
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${({ theme }) => theme.colorBackgroundWidget});
  }
`

const StyledDraggableItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${sizer(2, 4)};
  margin-top: ${tokens.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => theme.colorTextWeak};

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  div[draggable="true"]:first-of-type > & {
    margin-top: 0;
  }

  &.dragging {
    cursor: grabbing;
  }
`

export default function StyledDraggableList({
  dragLabel,
  keyProp = "value",
  keyProps = [], // ["value", "id", "tempId"]
  elements,
  onChange,
  onRemoveElement,
  renderElement,
  height,
  maxHeight,
  minHeight,
  listBottomRef,
  fadeAtBottom,
  isDisabled,
}) {
  return (
    <StyledContainer {...{ height, maxHeight, minHeight }}>
      {dragLabel && (
        <div className="drag-label">
          <Text size="xs" italic>
            {dragLabel}
          </Text>
        </div>
      )}
      <div className="draggable-list">
        <DragableList
          {...{
            keyProp,
            keyProps,
            elements,
            onChange,
            isDisabled,
            onRemoveElement,
            renderElement: (section, removeSection, isDragging) => {
              const { isRemovable = true } = section
              return (
                <StyledDraggableItem className={isDragging ? "dragging" : ""} tabIndex={-1}>
                  <Flex alignItems="center" flex="1" minWidth="1px" minHeight="32px">
                    <Box marginRight={sizer(3)}>
                      <GripLinesIcon size="sm" color="colorTextWeak" />
                    </Box>
                    <Box flex="1" minWidth="1px">
                      {renderElement(section)}
                    </Box>
                  </Flex>
                  {isRemovable && !isDisabled && (
                    <IconButton
                      size="sm"
                      handleClick={e => {
                        let element

                        if (keyProp) {
                          element = section[keyProp]
                        }

                        if (keyProps?.length) {
                          keyProps.forEach(_key => {
                            if (isNotNilOrEmpty(section[_key])) {
                              element = section[_key]
                            }
                          })
                        }

                        removeSection(e, element, section)
                      }}
                    >
                      <RemoveIcon color="colorTextWeak" />
                    </IconButton>
                  )}
                </StyledDraggableItem>
              )
            },
          }}
        />
        <div ref={listBottomRef} />
        {fadeAtBottom && <StyledFade />}
      </div>
    </StyledContainer>
  )
}
