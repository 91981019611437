import { useMemo } from "react"
import { insert, map, cond, is, T } from "ramda"
import { Text } from "@ninjaone/components"
import { clipboardWriteText, localized } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

/* eslint-disable no-template-curly-in-string */
const ticketSubjectPlaceholder = "${ticket.subject}"
const ticketDescriptionPlaceholder = "${ticket.description}"

const basicPlaceholders = [
  "${ticket.id}",
  "${ticket.createdDate}",
  "${ticket.device.displayName}",
  "${ticket.device.url}",
  ticketSubjectPlaceholder,
  "${ticket.status}",
  "${ticket.severity}",
  "${ticket.priority}",
  "${ticket.type}",
  "${ticket.tags}",
  "${ticket.organization.name}",
  "${ticket.organization.url}",
  "${ticket.assignee.email}",
  "${ticket.assignee.name}",
  "${ticket.assignee.firstName}",
  "${ticket.assignee.lastName}",
  "${ticket.assignee.phone}",
  "${ticket.requester.email}",
  "${ticket.requester.name}",
  "${ticket.requester.firstName}",
  "${ticket.requester.lastName}",
  "${ticket.requester.phone}",
  "${ticket.requester.organization.name}",
  "${ticket.url}",
]

const currentUserPlaceholders = [
  "${currentUser.email}",
  "${currentUser.name}",
  "${currentUser.firstName}",
  "${currentUser.lastName}",
  "${currentUser.phone}",
  "${currentUser.organization.name}",
]

const extendedPlaceholders = insert(
  basicPlaceholders.indexOf(ticketSubjectPlaceholder) + 1,
  ticketDescriptionPlaceholder,
  basicPlaceholders,
)

const mapPlaceholderOptions = map(option => ({
  value: option,
  label: option,
}))

const basicPlaceholderOptions = mapPlaceholderOptions(basicPlaceholders)
const currentUserPlaceholderOptions = mapPlaceholderOptions(currentUserPlaceholders)
const extendedPlaceholderOptions = mapPlaceholderOptions(extendedPlaceholders)

const OptionRenderer = ({ label }) => {
  return (
    <Flex data-clipboard-text={label} onClick={() => clipboardWriteText(label)}>
      <Text size="sm">{label}</Text>
    </Flex>
  )
}

const shouldShowLabel = cond([
  [is(Array), val => val.length],
  [T, val => !!val],
])

export const Placeholders = ({ extended = false, withCurrentUserPlaceholders = true }) => {
  const placeholderOptions = useMemo(() => {
    return [
      ...(extended ? extendedPlaceholderOptions : basicPlaceholderOptions),
      ...(withCurrentUserPlaceholders ? currentUserPlaceholderOptions : []),
    ]
  }, [extended, withCurrentUserPlaceholders])

  return (
    <div className="btn-group display-flex">
      <SearchableDropDown
        {...{
          width: "100%",
          options: placeholderOptions,
          openOnFocus: true,
          keepDropdownInView: true,
          rowRenderer: ({ label }) => <OptionRenderer {...{ label }} />,
          valueRenderer: selected => (shouldShowLabel(selected) ? localized("Search") : ""),
        }}
      />
    </div>
  )
}
