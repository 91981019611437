import { memo } from "react"

import { Text } from "@ninjaone/components"

import { localizationKey, localized } from "js/includes/common/utils"
import { ConfirmationModal } from "js/includes/components/ConfirmationModal"
import { Flex } from "js/includes/components/Styled"

export default memo(function ConfirmSyncToCloudModal({ name, displayName, unmount }) {
  const handleConfirm = async ({ unmount }) => {
    // TODO: Call to mount job when endpoint is available
    unmount()
  }
  return (
    <ConfirmationModal
      {...{
        type: "info",
        titleToken: localizationKey("Sync job to cloud"),
        actionToken: localizationKey("Sync"),
        onConfirm: handleConfirm,
        onCancel: ({ unmount }) => {
          unmount()
        },
        unmount,
      }}
    >
      <Flex flexDirection="column">
        <Text textWrap size="sm">
          {localized(
            "Backup Job that started {{name}} for {{displayName}} will be synced to cloud backup storage. Progress can be monitored as a running backup job for this device.",
            { name, displayName },
          )}
        </Text>
      </Flex>
    </ConfirmationModal>
  )
})
