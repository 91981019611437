import { initialDashboardState } from "js/state/reducers/virtualization/initialStates"
import { updateOwner } from "js/state/reducers/virtualization/commons/actionHandlers"
import { initDashboard, updateDeviceFriendlyName, setNodeStatus, updateWarrantyDates } from "./actionHandlers"

export default function vmHostDashboard(state = initialDashboardState(), action) {
  switch (action.type) {
    case "INIT_DASHBOARD_DATASETS":
      return initDashboard(state, action)
    case "INIT_NODE_DATA":
      return { ...state, node: action.node, organization: action.organization, location: action.location }
    case "CLEAR_NODE_DATA":
      return { ...state, node: null }
    case "UPDATE_NODE_FRIENDLY_NAME":
      return updateDeviceFriendlyName(state, action)
    case "UPDATE_HOST_NODE_OWNER":
      return updateOwner(state, action)
    case "SET_HOST_POLICIES_LIST":
      return { ...state, hostPolicyList: action.policies }
    case "SET_HEALTH_SENSOR_TYPE_FILTER":
      return { ...state, healhSensorTypeFilter: action.filterData }
    case "SET_HEALTH_SENSOR_STATUS_FILTER":
      return { ...state, healhSensorStatusFilter: action.filterData }
    case "CLEAR_HEALTH_SENSOR_TYPE":
      return { ...state, healhSensorTypeFilter: [] }
    case "CLEAR_HEALTH_SENSOR_STATUS":
      return { ...state, healhSensorStatusFilter: [] }
    case "SET_NODE_STATUS":
      return setNodeStatus(state, action)
    case "CLEAR_STATUS":
      return { ...state, triggeredConditions: [], activeJobs: [] }
    case "UPDATE_WARRANTY_DATES":
      return updateWarrantyDates(state, action)
    default:
      return state
  }
}
