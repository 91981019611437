import qs from "qs"
import {
  fetchJson,
  localizationKey,
  reportErrorAndShowMessage,
  dateTime,
  isFeatureEnabled,
  user,
} from "js/includes/common/utils"
import { isArrowImagePlan } from "js/includes/common/backup"
import {
  getRevisionsSecondaryGlobalActions,
  getArrowImageRevisionsActions,
  getMountImageContentAction,
} from "./actions"
import { getRevisionsColumns } from "./columns"

const getRevisionName = jobCreated => dateTime(jobCreated)

const fetchData = async ({
  currentDir: {
    name: parentName,
    planName,
    planId: planUuid,
    planType,
    fileId,
    planDeleted,
    volumeLetter,
    filteredVolumeName,
  },
  fetchingParams: { pageIndex, pageSize },
  node,
}) => {
  const { results, totalElements, totalPages } = await fetchJson(
    `/backup/lockhart/v2/nodes/${node.id}/plans/${planUuid}/files/${fileId}/revisions?${qs.stringify({
      page: pageIndex,
      size: pageSize,
    })}`,
  )

  const content = results.map(
    (
      { id, planId, type, size, backupSize, bldType, modified, jobCreated, jobCompleted, onS3, onNas, rollupSize },
      index,
    ) => {
      const canBrowseImageContent =
        isFeatureEnabled("cloud_image_mounting") && user("canViewAndRestoreBackupData") && onS3

      //TODO: refactor this if server-side sorting is implemented
      const isOldestRevision = totalPages - 1 === pageIndex && index + 1 === results.length

      return {
        node,
        id,
        name: getRevisionName(jobCreated),
        planId,
        planUuid,
        isPointInTime: true,
        planName,
        volumeLabel: parentName,
        volumeLetter,
        folder: canBrowseImageContent,
        actualSize: size,
        ...(isArrowImagePlan(planType) && isOldestRevision
          ? { backupSize: backupSize + rollupSize, rollupSize }
          : { backupSize }),
        type: "revisions",
        path: id.toString(),
        size,
        bldType,
        jobCreated,
        jobCompleted,
        onS3,
        onNas,
        planDeleted,
        filteredVolumeName,
      }
    },
  )

  return {
    data: content,
    pageCount: totalPages,
    totalCount: totalElements,
  }
}

const onError = (error, { name }) =>
  reportErrorAndShowMessage(error, localizationKey("There was an error fetching revisions for {{volumeName}}"), {
    volumeName: name,
  })

export const getRevisionsHandler = ({ currentDir: { planType, destination }, node }) => ({
  columns: getRevisionsColumns({ planType, destination }),
  actions: isArrowImagePlan(planType) ? getArrowImageRevisionsActions() : [],
  getBrowseRowAction: getMountImageContentAction,
  secondaryGlobalActions: getRevisionsSecondaryGlobalActions(node),
  fetchData,
  onError,
})
